.main-reffrel1 {
    // background-color: #fff;
    // display: flex;
    // align-items: center;
    // height: 100vh;
    background: url("../../src/Assets/bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
 height: 100vh;
 .mainlogo{
    padding: 50px 0px;
    text-align: center;
 }
    .main_box{
        background: #FFFFFF;
        box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.05);
        border-radius: 30px; 
        // width: 24% !important;
        margin: auto;
      }
    .inner-logo {
        img {
            width: 150px;
        }
    }
    .cmn-tile-style {
        background-color: #fff;
        border-radius: 10px;
        padding: 30px;
        margin-top: 10px;
        h2 {
            font-size: 30px;
        }

        h6 {
            font-weight: normal;
            color: #636363;
            text-align: center;
           
        }

        label {
            // font-family: 'Nunito'!important;
            font-style: normal!important;
            font-weight: bold!important;
            font-size: 16px!important;
            line-height: 22px!important;
            color: #000000!important;
            padding-top: 26px;
        }
        input {
            background: #FAFAFB;
            border: 1px solid #EFEFEF;
            box-sizing: border-box;
            border-radius: 5px;
            height: 50px;
            opacity: 1;
    visibility: visible;
            &::placeholder {
                color: #CDCDCD;
                font-family: 'Nunito';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
            }
        }
    }

    .inputErrors{
        color: red;
    }
}

@media only screen and (max-width: 600px) {
    .main-reffrel1 {
      .main_box{
          width: 90% !important;
      }
      .cmn-tile-style {
        padding: 2px !important;
      }
  }
}